import Q from 'q';

import trains_order_getBookingOrderPaymentInfo from '@/lib/data-service/trains/trains_order_getBookingOrderPaymentInfo';
import trains_order_orderPaymentStatus from '@/lib/data-service/trains/trains_order_orderPaymentStatus';
import PaymentMethodSelector from '@/lib/common-service/getPayModule';

export default {
    data() {
        return {
            orderNo: '',
            orderInfo: {},
            reload: 0,
            countDownTime: 0,
            countDownNumList: ['0', '00', '00', '00'],
            fromStationCode: '',
            toStationCode: '',
            time: '',
            type: 'reserve'
        }
    },
    components: {
        PaymentMethodSelector
    },
    created() {
        document.documentElement.scrollTop = 0;
        this.orderNo = this.$route.query.orderNo
        this.type = this.$route.query.type
        // this.fromStationCode = this.$route.query.fromStationCode
        // this.toStationCode = this.$route.query.toStationCode
        // this.time = this.$route.query.time
        let reload = this.$route.query.reload
        if (reload) {
            this.reload = parseInt(reload)
        }
    },
    async mounted() {
        if (this.reload === 1) {
            await this.getOrderStatus()
        }
        await this.getOrderInfo()
        this.__demo_PaymentMethodSelector_init()
        this.countDown(this.countDownTime)
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        toHome() {
            this.$router.push({
                name: 'distributor-train-front-home'
            })
        },
        async getOrderStatus() {
            let res = await trains_order_orderPaymentStatus({orderNo: this.orderNo})
            if (res.ifTimeout === 2) {
                // 超时
                return
            }
            this.countDownTime = res.minutes * 60 + res.second
        },
        countDown(mm) {
            let time = mm * 1000
            let setTime = () => {
                let day = parseInt(time / (24 * 60 * 60 * 1000))
                let d = time - day * 24 * 60 * 60 * 1000
                let hour = parseInt(d / (1000 * 60 * 60))
                let h = d - hour * 60 * 60 * 1000
                let minute = parseInt(h / (60 * 1000))
                // let m = h - minute * 60 * 1000
                let second = parseInt((time - day * 60 * 60 * 24 * 1000 - hour * 60 * 60 * 1000 - minute * 60 * 1000) / 1000)
                this.countDownNumList = [day, hour > 9 ? hour : '0' + hour, minute > 9 ? minute : '0' + minute, second > 9 ? second : '0' + second]
                time = time - 1000
                if (time < 0) {
                    clearInterval(interval)
                }
            };
            setTime()
            let interval = setInterval(() => {
                setTime()
            }, 1000)
        },
        async getOrderInfo() {
            let res = await trains_order_getBookingOrderPaymentInfo({orderNo: this.orderNo})
            this.orderInfo = res
            this.fromStationCode = res.fromStationCode
            this.toStationCode = res.toStationCode
            this.time = res.startDate
            if (this.reload === 0) {
                this.countDownTime = res.seconds
            }
        },
        __demo_PaymentMethodSelector_init() {
            let __this = this;
            this.$refs.aPaymentMethodSelector_000.init({
                get_params() {
                    const p = {
                        companyId: __this.orderInfo.companyId,
                        clubId: __this.orderInfo.clubId,//中间社id
                        orderNo: __this.orderInfo.orderNo, //订单编号
                        businessContent: __this.orderInfo.businessContent, //不用管里面的值是什么，订单详情返回的businessContent,直接传入到这里  this.orderInfo.businessContent
                        subject: __this.orderInfo.productName, //
                        orderAmount: __this.orderInfo.orderAmount, //订单金额，用于计算可以用哪些支付方式支付
                        clientType: 1,//客户端类型：1.b2b，2.b2c，3.差旅,默认为1
                    };
                    return Q.resolve(p);
                },

                count_down: {
                    enable: false,//是否启用倒计时，默认值为true
                    pay_remaining_seconds: __this.countDownTime, //支付剩余时间，以秒为单位
                },

                //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                before_pay_event_handler() {
                    // alert(`不能够支付！原因：XXX`);
                    // return Q.reject();
                    return Q.resolve();
                },
                //支付成功事件处理方法
                pay_successful_event_handler() {
                    console.log(`pay_successful_event_handler`);
                    // 预定
                    __this.$router.push({
                        name: 'distributor-train-front-pay-success',
                        query: {
                            orderNo: __this.orderNo,
                            status: 'success',
                            fromStationCode: __this.orderInfo.fromStationCode,
                            toStationCode: __this.orderInfo.toStationCode,
                            time: __this.time,
                            type: __this.type
                        }
                    })
                },
                //支付失败事件处理方法
                pay_failed_event_handler() {
                    console.log(`pay_failed_event_handler`);
                },
            });
        },
    }
}
