// trains_order_getBookingOrderPaymentInfo 获取预定订单支付信息

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址：
export default function trains_order_getBookingOrderPaymentInfo(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const orderNo = pParameter.orderNo || ''
  params.method = 'POST'
  params.urlSuffix = '/trains/order/getBookingOrderPaymentInfo'
  params.data = {
    orderNo,
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
